import router from '..'

const routes = {
  path: '/admin/system',
  name: 'AdminSystemMaster',
  meta: {
    layout: 'AdminLayout',
    title: 'system',
    dep: 5
  },
  component: () => import('@/views/admin/Main.vue'),
  children: [
    {
      path: 'manager',
      alias: '',
      name: 'AdminManagerWrite',
      title : '관리자 수정',
      component: () => import('@/views/admin/system/Write.vue'),
      meta: {
        lang : 'admin'
      }
    }
  ]
}

export default routes
