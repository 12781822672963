import router from '..'

const routes = {
  path: '/admin/project',
  name: 'AdminMaster',
  meta: {
    layout: 'AdminLayout',
    title: 'Dashboard',
    dep: 2
  },
  component: () => import('@/views/admin/Main.vue'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'AdminProjectList',
      title : 'DashBoard',
      component: () => import('@/views/admin/project/List.vue'),
      meta: {
        lang : 'admin'
      }
    },
    {
      path: 'write',
      alias: '',
      name: 'AdminProjectWrite',
      title : 'DashBoard',
      component: () => import('@/views/admin/project/Write.vue'),
      meta: {
        lang : 'admin'
      }
    }
  ]
}

export default routes
