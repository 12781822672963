import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store/index'
import commonAx from '@/api/common'

import adminProjectPath from './admin/path.project.js'
import adminMainPath from './admin/path.main.js'
import adminBoardPath from './admin/path.board.js'
import adminSystemPath from './admin/path.system.js'
import adminStatisticPath from './admin/path.statistic.js'
import adminSitePath from './admin/path.site.js'
import korAboutPath from './kor/path.about.js'
import korCurtainPath from './kor/path.curtain.js'
import korPrCenterPath from './kor/path.prcenter.js'
import korSupportPath from './kor/path.support.js'
import korRecruit from './kor/path.recruit.js'
import korProject from './kor/path.project.js'
import korGuide from './kor/path.guide.js'
import engAboutPath from './eng/path.about.js'
import engCurtainPath from './eng/path.curtain.js'
import engPrCenterPath from './eng/path.prcenter.js'
import engSupportPath from './eng/path.support.js'
import engRecruit from './eng/path.recruit.js'
import engProject from './eng/path.project.js'
import engGuide from './eng/path.guide.js'

const routes = [
  {
    path: '/',
    alias: '',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '@/views/Main.vue'),
    meta: {
      layout: 'WebLayout',
      title: '홈',
      dep: 8,
      lang : 'kor'
    }
  },
  {
    path: '/en',
    alias: '',
    name: 'EngMain',
    component: () => import(/* webpackChunkName: "main" */ '@/views/eng/Main.vue'),
    meta: {
      layout: 'EngLayout',
      title: '홈',
      dep: 8,
      lang : 'eng'
    }
  },
  {
    path: '/admin',
    alias: '',
    name: 'AdminLogin',
    component: () => import(/* webpackChunkName: "main" */ '@/views/admin/Login.vue'),
    meta: {
      layout: 'NoneLayout',
      title: '홈',
      dep: 8,
      lang : 'admin'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top : 0 }
  }
})


router.addRoute(adminProjectPath)
router.addRoute(adminMainPath)
router.addRoute(adminBoardPath)
router.addRoute(adminSystemPath)
router.addRoute(adminStatisticPath)
router.addRoute(adminSitePath)
router.addRoute(korAboutPath)
router.addRoute(korCurtainPath)
router.addRoute(korPrCenterPath)
router.addRoute(korSupportPath)
router.addRoute(korRecruit)
router.addRoute(korProject)
router.addRoute(korGuide)
router.addRoute(engAboutPath)
router.addRoute(engCurtainPath)
router.addRoute(engPrCenterPath)
router.addRoute(engSupportPath)
router.addRoute(engRecruit)
router.addRoute(engProject)
router.addRoute(engGuide)

router.beforeEach((to, from, next) => {
  //css 바인딩 초기 진입시 lang 설정
  if (to.meta.lang !=  store.getters.lang){
    store.commit('setLang', to.meta.lang)
    setCss(to.meta.lang)
  }
  if (to.meta.lang == 'admin'){

    if (store.getters.login && to.name != 'AdminLogin'){
      commonAx.ax('/admin/member/info', 'GET', {}, (flag, data) => {
        if (flag) {
          store.commit('login', data.data)
        } else {
          alert('오류가 발생하였습니다.')
        }
      })
    }
  }

  next()

})

function setCss(lang){

  var setting = false;
  const links = document.getElementsByTagName('head')[0].querySelectorAll('link');
  links.forEach(link => {
    if (link.href.indexOf('css/kor') > 0){
      setting = true;
    }

  });
  console.dir(setting)
  if (lang != 'admin'){
    if (!setting){
      links.forEach(link => {
        if (link.href.indexOf('.css') > 0){
          link.remove();
        }
      });
      var element = document.createElement('link');
      var script = document.createElement('script');
      element.setAttribute('rel', 'stylesheet');
      element.setAttribute('type', 'text/css');
      element.setAttribute('href', '/css/'+lang+'/bootstrap.min.css');
      document.getElementsByTagName('head')[0].appendChild(element);
      element = document.createElement('link');
      element.setAttribute('rel', 'stylesheet');
      element.setAttribute('type', 'text/css');
      element.setAttribute('href', '/css/'+lang+'/hub.css');
      document.getElementsByTagName('head')[0].appendChild(element);
      element = document.createElement('link');
      element.setAttribute('rel', 'stylesheet');
      element.setAttribute('type', 'text/css');
      element.setAttribute('href', '/css/'+lang+'/main.css');
      document.getElementsByTagName('head')[0].appendChild(element);
      element = document.createElement('link');
      element.setAttribute('rel', 'stylesheet');
      element.setAttribute('type', 'text/css');
      element.setAttribute('href', '/css/'+lang+'/_ico/xeicon.min.css');
      document.getElementsByTagName('head')[0].appendChild(element);
    }
    /*
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', '/js/'+lang+'/ui.js');
    document.getElementsByTagName('head')[0].appendChild(script);
    */
    //window.$cookies.remove('ijUniscoVisit')

    if (!window.$cookies.get('ijUniscoVisit')){
      window.$cookies.set('ijUniscoVisit', true, '1d')
      let params = {};
      params.referer = window.document.referrer

      commonAx.ax('/common/visit', 'GET', params, (flag, data) => {
        if (flag) {
          console.dir('방문 카운트')
        } else {
          alert('오류가 발생하였습니다.')
        }
      })
    }
  } else {

    links.forEach(link => {
      if (link.href.indexOf('.css') > 0){
        link.remove();
      }
    });
    element = document.createElement('link');
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('type', 'text/css');
    element.setAttribute('href', '/css/'+lang+'/default.css');
    document.getElementsByTagName('head')[0].appendChild(element);

    element = document.createElement('link');
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('type', 'text/css');
    element.setAttribute('href', '//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css');
    document.getElementsByTagName('head')[0].appendChild(element);

    element = document.createElement('link');
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('type', 'text/css');
    element.setAttribute('href', '/css/'+lang+'/datepicker.css');
    document.getElementsByTagName('head')[0].appendChild(element);

    /*
    script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', '/js/'+lang+'/ui.js');
    document.getElementsByTagName('head')[0].appendChild(script);*/

  }


}






export default router
