
<template>


    <!-- Wrap -->
    <div id="Wrap">
      <Header />
      <slot />
      <Footer />
    </div>

</template>

<script>

import Header from '@/components/AdmHeader.vue'
import Footer from '@/components/AdmFooter.vue'

export default {
  name: 'AdminLayout',
  components: {
    Footer,
    Header
  },
  mounted() {
    function fn_SetClock () {
      var currentDate=new Date();
      var nowClock = $('#nowClock');

      var year = currentDate.getFullYear(); // 년도
      var month = currentDate.getMonth() + 1;  // 월
      var date = currentDate.getDate();  // 날짜

      var hour = currentDate.getHours()
      var min = currentDate.getMinutes()
      var sec = currentDate.getSeconds()
      var msg = year + '.'+ fn_setZero(month) + '.' + fn_setZero(date) +' ';
      msg += fn_setZero(hour)  +':'+ fn_setZero(min) + ':' + fn_setZero(sec);

      nowClock.html('<i class="xi-alarm-clock-o" ></i>'+msg);
      setTimeout(fn_SetClock, 1000);
    }

    function fn_setZero(time){
      if (time < 10){
        time = '0'+time;
      }
      return time;
    }


    $(document).ready(function() {
      fn_SetClock();
    });
  }


}
</script>
