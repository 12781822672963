import router from '..'

const routes = {
  path: '/support',
  name: 'KorSupportMain',
  meta: {
    layout: 'WebLayout',
    title: '센터관리',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/support/Main.vue'),
  children: [
    {
      path: 'inquire',
      alias: '',
      name: 'KorInquire',
      title : '문의하기',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/support/Inquire.vue'),
      meta: {
        btnOn: 1,
        lang : 'kor'
      }
    },
    {
      path: 'audit',
      alias: '',
      name: 'KorAudit',
      title : '사이버감사실',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/support/Audit.vue'),
      meta: {
        btnOn: 1,
        lang : 'kor'

      }
    }
  ]
}

export default routes
