import router from '..'

const routes = {
  path: '/project',
  name: 'KorProjectMain',
  meta: {
    layout: 'WebLayout',
    title: 'Project',
    dep: 1
  },
  component: () => import('@/views/kor/curtain/Main.vue'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'KorDomestic',
      title : '프로젝트',
      component: () => import('@/views/kor/project/Domestic.vue'),
      meta: {
        lang : 'kor'
      }
    },
    {
      path: 'global',
      alias: '',
      name: 'KorGlobal',
      title : '커튼월 소개',
      component: () => import('@/views/kor/project/Global.vue'),
      meta: {
        lang : 'kor'
      }
    },
    {
      path: 'view',
      alias: '',
      name: 'KorProjectView',
      title : '프로젝트',
      component: () => import('@/views/kor/project/View.vue'),
      meta: {
        lang : 'kor'
      }
    }
  ]
}

export default routes
