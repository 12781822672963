'use strict'

import axios from 'axios'
import store from '@/store'
import {deCrypted} from '@/utils/cryptJs'

const _axios = axios.create({
  baseURL: window.location.protocol +'//'+window.location.hostname + process.env.VUE_APP_API_URL,
  withCredentials: true,
  timeout: 10000
})
console.dir(window.location.protocol +'//'+window.location.hostname + process.env.VUE_APP_API_URL)
_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let accessToken = sessionStorage.getItem('eotoken') || localStorage.getItem('eotoken')
    config.headers.Authorization = 'Bearer ' + accessToken
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  (response) => {
    // if (response.data.code === '110') {
    //   store.dispatch('logout')
    //   window.location.href = '/login'
    // }
    // response.data = deCrypted(JSON.stringify(response.data))

    if (response.headers.eotoken != null){

      sessionStorage.setItem('eotoken', response.headers.eotoken);
      localStorage.setItem('eotoken', response.headers.eotoken);
    }
    return response
  },
  async (error) => {
    const {
      config,
      response: {status, data}
    } = error
    // const originalRequest = config
    if (status === 401) {
      return new Promise((resolve) => {

        sessionStorage.removeItem('eotoken');
        localStorage.removeItem('eotoken');
        window.location.href = '/admin'
        return Promise.reject(new Error('로그인이 만료 되었습니다.1'))

        // auth.refreshToken((flag, accessToken) => {
        //   if (flag) {
        //     originalRequest.headers.Authorization = accessToken
        //     resolve(axios(originalRequest))
        //   } else {
        //     store.dispatch('logout')
        //     window.location.href = '/'
        //     return Promise.reject(new Error('로그인이 만료 되었습니다.'))
        //   }
        // })
      })
    }  else if (status === 403) {
      return new Promise((resolve) => {

        sessionStorage.removeItem('eotoken');
        localStorage.removeItem('eotoken');
        window.location.href = '/admin'
        //this.$router.replace('/member/login')  // 로그인 재 설정
        return Promise.reject(new Error('권한이 없습니다.'))

        // auth.refreshToken((flag, accessToken) => {
        //   if (flag) {
        //     originalRequest.headers.Authorization = accessToken
        //     resolve(axios(originalRequest))
        //   } else {
        //     store.dispatch('logout')
        //     window.location.href = '/'
        //     return Promise.reject(new Error('로그인이 만료 되었습니다.'))
        //   }
        // })
      })

    } else {
      alert('오류가 발생하였습니다.')
      // await store.dispatch('logout')
      // window.location.href = '/login'
      return Promise.reject(new Error('통신에 오류가 있습니다.'))
    }
  }
)

export default _axios
