<template>
	<!-- footer -->
<footer id="footer">
	<p>
		Copyright © auton.com. All rights reserved. <br>
	</p>
	<a href="#." title="top" class="scroll-top"><i class="xi-arrow-up"></i></a>
</footer>
<!-- //footer -->
</template>

<script>
export default {
  name: 'Footer'
}
</script>

