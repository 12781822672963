import router from '..'

const routes = {
  path: '/en/prcenter',
  name: 'EngPrCenterMain',
  meta: {
    layout: 'EngLayout',
    title: '센터관리',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "" */ '@/views/eng/prcenter/Main.vue'),
  children: [
    {
      path: 'ci',
      alias: '',
      name: 'EngPrCenterCi',
      title : 'Ci',
      component: () => import(/* webpackChunkName: "" */ '@/views/eng/prcenter/Ci.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'
      }
    },
    {
      path: 'news',
      alias: '',
      name: 'EngPrCenterNews',
      title : 'News',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/prcenter/News.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'

      }
    },

    {
      path: 'news/view',
      alias: '',
      name: 'EngPrCenterNewsView',
      title : 'News',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/prcenter/NewsView.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'

      }
    },
    {
      path: 'video',
      alias: '',
      name: 'EngPrCenterVideo',
      title : 'Video',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/prcenter/Video.vue'),
      meta: {
        btnOn: 1,
        lang : 'emg'

      }
    },
  ]
}

export default routes
