import {createApp} from 'vue'
import commonAx from './api/common'
import App from './App.vue'
import router from './router'
import store from './store'

import {VueCookieNext} from 'vue-cookie-next'
import vueCookies from 'vue-cookies';
import VueClipboard from 'vue3-clipboard'
import dayjs from 'dayjs'
import commonUtil from './utils/common'



const app = createApp(App)
app.use(VueCookieNext)
app.config.globalProperties.$store = store
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$ax = commonAx.ax

app.use(store)
app.use(vueCookies).use(router);
app.use(router).mount('#app')

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(dayjs)
app.use(commonUtil)
app.$cookies.config('7d');
