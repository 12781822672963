import router from '..'

const routes = {
  path: '/en/recruit',
  name: 'EngRecruitMain',
  meta: {
    layout: 'EngLayout',
    title: 'Recruit',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/recruit/Main.vue'),
  children: [
    {
      path: 'talent',
      alias: '',
      name: 'EngTalent',
      title : '인재상',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/recruit/Talent.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'

      }
    },
    {
      path: 'employment',
      alias: '',
      name: 'EngEmployment',
      title : '인사재도',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/recruit/Employment.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'
      }
    },
    {
      path: 'welfare',
      alias: '',
      name: 'EngWelfare',
      title : '임금복리후생',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/recruit/Welfare.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'

      }
    }

  ]
}

export default routes
