<template>
  <component :is="layout ? layout : 'NoneLayout'">
    <router-view />
  </component>
</template>

<script>
import DefaultLayout from './DefaultLayout'
import SubLayout from './SubLayout'
import AdmLayout from './AdmLayout'
import NoneLayout from './NoneLayout'
import WebLayout from './WebLayout'
import AdminLayout from './AdminLayout'
import EngLayout from './EngLayout'


export default {

  name: 'BaseLayout',
  components: {
    DefaultLayout,
    SubLayout,
    AdmLayout,
    NoneLayout,
    WebLayout,
    AdminLayout,
    EngLayout
  },
  data() {
    return {}
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'NoneLayout'
    }
  }
}
</script>
