<template>
 <!-- header -->
		<header id="header">
			<div class="inner">
				<h1 class="en_logo"><router-link :to="{name: 'EngMain' }" >LOTTE EcoWall</router-link></h1>
				<nav class="gnb">
					<ul class="list-unstyled">
						<li class="dp1">
							<router-link :to="{name: 'EngGreeting' }" class="on">ABOUT US</router-link>
							<ul>
								<li><router-link :to="{name: 'EngGreeting' }">CEO message</router-link></li>
								<li><router-link :to="{name: 'EngManagement' }">Management Philosophy</router-link></li>
								<li><router-link :to="{name: 'EngHistory' }" class="on">Company History</router-link></li>
								<!-- <li><a href="/iljin_eng/aboutus/group.jsp">그룹안내</a></li> -->
								<li><router-link :to="{name: 'EngLocation' ,query:{location:'B'} }">Location Guide</router-link></li>
								<li><router-link :to="{name: 'EngCurtainWallProcess', query:{location:'PD'} }">Factory Introduction</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'EngCurtainWall' }">CURTAIN WALL</router-link>
							<ul>
								<li><router-link :to="{name: 'EngCurtainWall' }">CURTAIN WALL</router-link></li>
								<li><router-link :to="{name: 'EngCurtainWallProcess', query:{location:'PR'} }">PROCESS</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'EngDomestic' }">PROJECTS</router-link>
							<ul>
								<li><router-link :to="{name: 'EngDomestic', query:{lang:'eng', type:'D'} }">Domestic Project</router-link></li>
								<li><router-link :to="{name: 'EngDomestic', query:{lang:'eng', type:'O'}}">Overseas Project</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'EngPrCenterCi' }">PR CENTER</router-link>
							<ul>
								<li><router-link :to="{name: 'EngPrCenterCi' }">CI Introduction</router-link></li>
								<li><router-link :to="{name: 'EngPrCenterNews' }">LOTTE EcoWall News</router-link></li>
								<li><router-link :to="{name: 'EngPrCenterVideo' }">Promotional Video</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'EngInquire' }">SUPPORT</router-link>
							<ul>
								<li><router-link :to="{name: 'EngInquire' }">Inquiries</router-link></li>
								<!-- <li><router-link :to="{name: 'EngAudit' }">Cyber Audit</router-link></li> -->
							</ul>
						</li>
						<!-- <li class="dp1">
							<router-link :to="{name: 'EngTalent' }">RECRUIT</router-link>
							<ul>
								<li><router-link :to="{name: 'EngTalent' }">Talent</router-link></li>
								<li><router-link :to="{name: 'EngEmployment' }">Personnel management</router-link></li>
								<li><router-link :to="{name: 'EngWelfare' }">Wages and benefits</router-link></li>
							</ul>
						</li> -->
					</ul>
				</nav>
				<div class="gnb_side">
					<div class="lan">
						<a style="cursor:pointer" @click="goKor()" >KR</a>
						<a style="cursor:pointer"  class="on">EN</a>
					</div>
					<div class="burger">
						<div class="x"></div>
						<div class="y"></div>
						<div class="z"></div>
					</div>
				</div>
			</div>
			<div class="circle"></div>
		</header>
		<!-- //header -->
</template>

<script>

export default {
  name: 'WebHeader',
  data() {
    return {
      userName: '',
    }
  },

  computed: {
    title() {
      return this.$route.meta.title || ''
    }

  },
  mounted() {
    console.dir(this.$store)
  },
  methods: {
    leftHidden() {
      this.$emit('leftHidden')
    },
    logout() {
      sessionStorage.removeItem('eotoken')
      localStorage.removeItem('eotoken')
      this.$router.push({
        name: 'Login'
      })
    },
    goKor(){
      if (this.$route.name == 'EngMain'){
        this.$router.push({
          name: 'Main'
        })
        return;
      }
      if (this.$route.name =='EngPrCenterNewsView'){
        this.$router.push({
          name: 'KorPrCenterNews'
        })
        return;
      }
      if (this.$route.name =='EngProjectView'){
        this.$router.push({
          name: 'KorDomestic',
          query : {lang:'kor', type:this.$route.query.type}
        })
        return;
      }
      if (this.$route.name =='EngCurtainWallProcess'){
        this.$router.push({
          name: 'KorCurtainWallProcess',
          query : {location:this.$route.query.location}
        })
        return;
      }


      if (this.$route.name =='EngLocation'){
        this.$router.push({
          name: 'KorLocation',
          query : {location:this.$route.query.location}
        })
        return;
      }




      this.$router.push({
        name: this.$route.name.replace('Eng', 'Kor')
      })

    }

  }
}
</script>
