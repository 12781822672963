import router from '@/router'
import { inject } from 'vue'

export const Member = {
  state: () => ({
    provision: [false, false, false],
    userId: '',
    name: '',
    role : '',
  }),
  mutations: {
    // this.$store.commit("setProvision", provision)
    setProvision(state, provision) {
      state.provision = provision
    },
    setEmail(state, email) {
      state.email = email
    },
    setNameBirthGender(state, form) {
      state.name = form.name
      state.birth = form.birth
      state.gender = form.gender
    },
    setRole(state, form) {
      state.role = form.role
    },
    reset(state) {
      state = {
        provision: [false, false, false],
        email: '',
        name: '',
        birth: '',
        gender: '',
        nick: '',
        hp1: '010',
        hp2: '',
        hp3: '',
        pwd: '',
        pwd2: '',
        role : '',
      }
    },
    login(state, payload){
      state.userId = payload.userId
      state.name = payload.name
      state.role = payload.role

    }
  },
  actions: {
    // this.$store.dispatch("setProvision_test", provision)
    setProvision: function (context, payload) {
      // commit 의 대상인 addCount 는 mutations 의 메서드를 의미한다.
      // 비동기 로직 수행후
      return setTimeout(function () {
        context.commit('setProvision', payload.provision)
      }, 1000)
    },
    resetMember: function (context, payload) {
      // 비동기 로직 수행후
      return setTimeout(function () {
        context.commit('reset')
      }, 1000)
    },
    logout() {
      localStorage.removeItem('eotoken')
      router.replace('/')
    },
    login(context, payload) {
      context.commit('login', payload)
    },
  },
  getters: {
    // $store.getters.member
    member(state) {
      if (state.userId ==''){

      }
      return state
    }
  }
}
