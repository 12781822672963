import router from '..'

const routes = {
  path: '/en/guide',
  name: 'EngGuideMain',
  meta: {
    layout: 'EngLayout',
    title: 'informaiton',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/guide/Main.vue'),
  children: [
    {
      path: 'privacy',
      alias: '',
      name: 'EngPrivacy',
      title : 'Private Information Handling Policy',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/guide/Privacy.vue'),
      meta: {
        lang : 'eng'
      }
    },
    {
      path: 'email',
      alias: '',
      name: 'EngEmail',
      title : 'Unauthorized Collection of Email Denied',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/guide/Email.vue'),
      meta: {
        lang : 'eng'

      }
    }
  ]
}

export default routes
