import router from '..'

const routes = {
  path: '/admin/site',
  name: 'AdminSiteMaster',
  meta: {
    layout: 'AdminLayout',
    title: 'system',
    dep: 1
  },
  component: () => import('@/views/admin/Main.vue'),
  children: [
    {
      path: 'popup/list',
      alias: '',
      name: 'AdminPopupList',
      title : '팝업관리',
      component: () => import('@/views/admin/site/PopupList.vue'),
      meta: {
        lang : 'admin'
      }
    },
    {
      path: 'popup/write',
      alias: '',
      name: 'AdminPopupWrite',
      title : '팝업관리',
      component: () => import('@/views/admin/site/PopupWrite.vue'),
      meta: {
        lang : 'admin'
      }
    },
    {
      path: 'banner/write',
      alias: '',
      name: 'AdminBannerWrite',
      title : '팝업관리',
      component: () => import('@/views/admin/site/BannerWrite.vue'),
      meta: {
        lang : 'admin'
      }
    }

  ]
}

export default routes
