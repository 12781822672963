<template>
  <div class="topwrap">
    <router-link :to="{name:''}" class="text14 mo" v-if="this.notiYn=='Y'"><span>new</span> 새로운공지사항이있습니다 <button type="button"><img src="/img/ico_elis.png"></button></router-link>
    <div class="top">
      <button type="button" @click="leftHidden" class="menu_btn"><img src="/img/ico_menu.png" /></button>
      <p class="title" v-if="title !== 'hidden'">{{ title }}</p>
      <div class="my">
        <router-link :to="{name:''}" class="text14 pc" v-if="this.notiYn=='Y'"><span>new</span> 새로운공지사항이있습니다 </router-link>
        <p class="text16">{{ this.$store.getters.member.name }} 님</p>
        <button type="button" class="linebtn" @click="logout">로그아웃</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Subheader',
  data() {
    return {
      userName: '',
    }
  },

  computed: {
    title() {
      return this.$route.meta.title || ''
    }

  },
  mounted() {
    console.dir(this.$store)
  },
  methods: {
    leftHidden() {
      this.$emit('leftHidden')
    },
    logout() {
      sessionStorage.removeItem('eotoken')
      localStorage.removeItem('eotoken')
      this.$router.push({
        name: 'Login'
      })
    },

  }
}
</script>
