import router from '..'

const routes = {
  path: '/en/curtainwall',
  name: 'EngCurtainMain',
  meta: {
    layout: 'EngLayout',
    title: 'About Us',
    dep: 1
  },
  component: () => import('@/views/eng/curtain/Main.vue'),
  children: [
    {
      path: '',
      alias: '',
      name: 'EngCurtainWall',
      title : '커튼월 소개',
      component: () => import('@/views/eng/curtain/CurtainWall.vue'),
      meta: {
        lang : 'eng'

      }
    },
    {
      path: 'process',
      alias: '',
      name: 'EngCurtainWallProcess',
      title : '커튼월 Process',
      component: () => import('@/views/eng/curtain/Process.vue'),
      meta: {
        lang : 'eng'

      }
    },

  ]
}

export default routes
