<template>
  <header id="header">
	<h1>유니스코</h1>

	<div id="hd_top">
	  <div id="logo"><router-link :to="{name: 'AdminDashBoard' }"><img src="/img/admin/logo_white.png" style="max-width:200px" alt="롯데에코월" title=""></router-link></div>
		<div id="tnb">
			<ul>
				<li class="tnb_li"><a href="#" @click="logout"><i class="xi-log-out"></i> 로그아웃</a></li>
			</ul>
		</div>
		<!-- gnb -->
		<nav id="gnb" class="gnb-large ">
			<h2>관리자 주메뉴</h2>
			<ul class="gnb-ul">
				<li class="gnb-li">
					<router-link :to="{name: 'AdminPopupList', query:{}}">사이트 관리</router-link>
					<div class="gnb-oparea">
						<ul>
							<li><router-link :to="{name: 'AdminPopupList', query:{}}" class="gnb_2da">팝업관리</router-link></li>
							<li><router-link :to="{name: 'AdminBannerWrite', query:{lang :'kor'}}" class="gnb_2da">메인배너관리 (국문)</router-link></li>
              <li><router-link :to="{name: 'AdminBannerWrite', query:{lang :'eng'}}" class="gnb_2da">메인배너관리 (영문)</router-link></li>
						</ul>
					</div>
				</li>
				<li class="gnb-li">
					<router-link :to="{name: 'AdminProjectList', query:{lang:'kor', type:'D'}}" >프로젝트 관리</router-link>
					<div class="gnb-oparea">
						<ul>
							<li><router-link :to="{name: 'AdminProjectList', query:{lang:'kor', type:'D'}}" class="gnb_2da  ">국내 프로젝트 (국문)</router-link></li>
							<li><router-link :to="{name: 'AdminProjectList', query:{lang:'kor', type:'O'}}" class="gnb_2da  ">해외 프로젝트 (국문)</router-link></li>
              <li><router-link :to="{name: 'AdminProjectList', query:{lang:'eng', type:'D'}}" class="gnb_2da  ">국내 프로젝트 (영문)</router-link></li>
							<li><router-link :to="{name: 'AdminProjectList', query:{lang:'eng', type:'O'}}" class="gnb_2da  ">해외 프로젝트 (영문)</router-link></li>
						</ul>
					</div>
				</li>
				<li class="gnb-li">
					<router-link :to="{name: 'AdminNewsList', query:{lang:'kor'}}">게시판 관리</router-link>
					<div class="gnb-oparea">
						<ul>
							<li><router-link :to="{name: 'AdminNewsList', query:{lang:'kor'}}" class="gnb_2da">뉴스/매거진 (국문)</router-link></li>
              <li><router-link :to="{name: 'AdminNewsList', query:{lang:'eng'}}" class="gnb_2da">뉴스/매거진 (영문)</router-link></li>
							<li><router-link :to="{name: 'AdminInquireList'}" class="gnb_2da">문의하기</router-link></li>
						</ul>
					</div>
				</li>
				<li class="gnb-li">
					<router-link :to="{name: 'AdminStatsVisit', query:{}}" >통계 관리</router-link>
					<div class="gnb-oparea">
						<ul>
							<li><router-link :to="{name: 'AdminStatsVisit', query:{}}" class="gnb_2da">방문통계</router-link></li>
							<li><router-link :to="{name: 'AdminStatsInflow', query:{}}" class="gnb_2da">유입경로</router-link></li>
						</ul>
					</div>
				</li>
				<li class="gnb-li">
					<router-link :to="{name: 'AdminManagerWrite', query:{}}" >시스템 관리</router-link>
					<div class="gnb-oparea">
						<ul>
							<li><router-link :to="{name: 'AdminManagerWrite', query:{}}" class="gnb_2da">관리자관리</router-link></li>
						</ul>
					</div>
				</li>
			</ul>
		</nav>
		 <!-- //gnb -->
	</div>

	<aside id="sideNav" class="side-nav">
		<h2>
		{{ this.$store.getters.member.name }} ({{ this.$store.getters.member.userId }})
		<p class="clock" id="nowClock"><i class="xi-alarm-clock-o"></i>2022.08.26 11:46:59</p>
		</h2>
		<div class="my-site clearfix">
			<p><a href="/" target="_blank"><i class="xi-home"></i> 홈페이지 가기</a></p>
			<p><a href="javascript:bookmarksite('라온보드', '/main')"><i class="xi-star"></i>즐겨찾기 추가</a></p>
		</div>
		<nav>
			<ul>
        <li class="gnb-li on" v-if="this.page == 1 ">
					<h3>사이트 관리</h3>
					<ul>
						<li><router-link :to="{name: 'AdminPopupList', query:{lang :'kor'}}" class="gnb_2da">팝업관리(국문)</router-link></li>
            <li><router-link :to="{name: 'AdminPopupList', query:{lang :'eng'}}" class="gnb_2da">팝업관리(영문)</router-link></li>
						<li><router-link :to="{name: 'AdminBannerWrite', query:{lang :'kor'}}" class="gnb_2da">메인배너관리 (국문)</router-link></li>
            <li><router-link :to="{name: 'AdminBannerWrite', query:{lang :'eng'}}" class="gnb_2da">메인배너관리 (영문)</router-link></li>
					</ul>
				</li>
        <li class="gnb-li on" v-if="this.page == 2 ">
					<h3>프로젝트 관리</h3>
					<ul>
						<li><router-link :to="{name: 'AdminProjectList', query:{lang:'kor', type:'D'}}" class="gnb_2da">국내 프로젝트 (국문)</router-link></li>
            <li><router-link :to="{name: 'AdminProjectList', query:{lang:'kor', type:'O'}}" class="gnb_2da">해외 프로젝트 (국문)</router-link></li>
            <li><router-link :to="{name: 'AdminProjectList', query:{lang:'eng', type:'D'}}" class="gnb_2da">국내 프로젝트 (영문)</router-link></li>
            <li><router-link :to="{name: 'AdminProjectList', query:{lang:'eng', type:'O'}}" class="gnb_2da">해외 프로젝트 (영문)</router-link></li>
					</ul>
				</li>
        <li class="gnb-li on" v-if="this.page == 3 ">
					<h3>게시판 관리</h3>
					<ul>
						<li><router-link :to="{name: 'AdminNewsList', query:{lang:'kor'}}" class="gnb_2da">뉴스/매거진 (국문)</router-link></li>
            <li><router-link :to="{name: 'AdminNewsList', query:{lang:'eng'}}" class="gnb_2da">뉴스/매거진 (영문)</router-link></li>
            <li><router-link :to="{name: 'AdminInquireList'}" class="gnb_2da">문의하기</router-link></li>
					</ul>
				</li>
        <li class="gnb-li on" v-if="this.page == 4 ">
					<h3>통계 관리</h3>
					<ul>
						<li><router-link :to="{name: 'AdminStatsVisit', query:{}}" class="gnb_2da">방문통계</router-link></li>
            <li><router-link :to="{name: 'AdminStatsInflow', query:{}}" class="gnb_2da">유입경로</router-link></li>

					</ul>
				</li>
				<li class="gnb-li on" v-if="this.page == 5 ">
					<h3>시스템 관리</h3>
					<ul>
						<li><router-link :to="{name: 'AdminManagerWrite', query:{}}" class="gnb_2da">관리자 관리</router-link></li>
					</ul>
				</li>

			</ul>
		</nav>
	</aside>

</header>
</template>

<script>

export default {
  name: 'AdmHeader',
  data() {
    return {
      page: this.$route.meta.dep
    }
  },
  watch: {
    '$route.name': function () {
      console.log(this.$route.name)
      this.page = this.$route.meta.dep
    }
  },
  methods : {
    logout(){
      sessionStorage.removeItem('eotoken');
      localStorage.removeItem('eotoken');
      this.$router.push({
        name: 'AdminLogin',

      });
    }
  }
}
</script>
