
<template>
  <!-- footer -->
		<footer id="footer" class="container">
			<div class="f_link">
				<!-- <ul>
					<li><router-link :to="{name: 'EngPrivacy' }">Private Information Handling Policy</router-link></li>
					<li><router-link :to="{name: 'EngEmail' }">Unauthorized Collection of Email Denied</router-link></li>
				</ul> -->
			</div>
			<div class="f_info">
				<div class="info">
					<address>
						<p>Headquarters and Sales Department. 333 Yeongdong-daero, Gangnam-gu, Seoul, Korea </p>
						<p>Phone. 02)6353-0114</p>
						<p>Fax. 02)6353-0100</p>
					</address>
					<p class="copy">LOTTE EcoWall Co. ALL RIGHTS RESERVED.</p>
				</div>
				<!-- <div class="family">
					<select  onchange="window.open(value,'_blank');">
						<option>Family Site</option>
						<option value="http://www.iljin.co.kr">ILJIN Group</option>
						<option value="http://www.iljinelectric.com">ILJIN Electric</option>
						<option value="http://www.iljinm.co.kr/eng/index.asp">ILJIN Materials</option>
						<option value="http://www.iljindiamond.com/eng/index.html">ILJIN Diamond</option>
						<option value="http://www.iljindisplay.com">ILJIN Display</option>
						<option value="http://www.iljinsteel.com/english/index.asp">ILJIN Steel</option>
						<option value="https://www.iljincns.co.kr/">ILJIN C&S</option>
						<option value="http://www.composite.co.kr/eng/main.jsp">ILJIN Hysolus</option>
						<option value="http://www.iljinec.co.kr/">ILJIN E&C</option>
					</select>
				</div> -->
			</div>
		</footer>	
		<!-- //footer -->

</template>

<script>

export default {
  name: 'Subheader',
  data() {
    return {
      userName: '',
    }
  },

  computed: {
    title() {
      return this.$route.meta.title || ''
    }

  },
  mounted() {
    console.dir(this.$store)
  },
  methods: {
    leftHidden() {
      this.$emit('leftHidden')
    },


  }
}
</script>
