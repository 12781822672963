import router from '..'

const routes = {
  path: '/prcenter',
  name: 'KorPrCenterMain',
  meta: {
    layout: 'WebLayout',
    title: '센터관리',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "" */ '@/views/kor/prcenter/Main.vue'),
  children: [
    {
      path: 'ci',
      alias: '',
      name: 'KorPrCenterCi',
      title : 'Ci',
      component: () => import(/* webpackChunkName: "" */ '@/views/kor/prcenter/Ci.vue'),
      meta: {
        btnOn: 1,
        lang : 'kor'
      }
    },
    {
      path: 'news',
      alias: '',
      name: 'KorPrCenterNews',
      title : 'News',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/prcenter/News.vue'),
      meta: {
        btnOn: 1,
        lang : 'kor'

      }
    },

    {
      path: 'news/view',
      alias: '',
      name: 'KorPrCenterNewsView',
      title : 'News',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/prcenter/NewsView.vue'),
      meta: {
        btnOn: 1,
        lang : 'kor'

      }
    },
    {
      path: 'video',
      alias: '',
      name: 'KorPrCenterVideo',
      title : 'Video',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/prcenter/Video.vue'),
      meta: {
        btnOn: 1,
        lang : 'kor'

      }
    },
  ]
}

export default routes
