
<template>
  <div class="d-flex" id="wrapper">
    <Header :class="{'collapse':menuVisible}" />
    <div id="page-content-wrapper">
      <Subheader @leftHidden="leftHidden" />
      <slot />
    </div>
  </div>
</template>

<script>
import Header from '@/components/AdmHeader.vue'
import Subheader from '@/components/Subheader.vue'

export default {
  name: 'AdmLayout',
  data() {
    return {
      menuVisible: false,
    }
  },
  components: {
    Header,
    Subheader
  },
  mounted() {
    /*let user = navigator.userAgent;
    let is_mobile = false;
    $("meta[name='viewport']").attr("content", '' );
    if ( user.indexOf('iPhone') > -1 || user.indexOf('Android') > -1 ) {
      is_mobile = true;
    }
    if (is_mobile){
      this.menuVisible =true
    }*/


  },
  computed: {
    name: function() {
      return this.$route.name ? this.$route.name : ''
    }
  },
  methods: {
    leftHidden(){
      this.menuVisible=!this.menuVisible
    }
  },
  watch: {
    '$route.name': function() {
      $("meta[name='viewport']").attr("content", '' );
      let user = navigator.userAgent;
      let is_mobile = false;

      if ( user.indexOf('iPhone') > -1 || user.indexOf('Android') > -1 ) {
        is_mobile = true;
      }
      if (is_mobile){
        this.menuVisible =true
      }
    }
  }
}

</script>
