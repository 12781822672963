<template>
  <!-- header -->
		<header id="header">
			<div class="inner">
				<h1><router-link :to="{name: 'Main' }" class="on">롯데에코월</router-link></h1>
				<nav class="gnb">
					<ul class="list-unstyled">
						<li class="dp1">
							<router-link :to="{name: 'KorGreeting' }" class="on">ABOUT US</router-link>
							<ul>
								<li><router-link :to="{name: 'KorGreeting' }">CEO인사말</router-link></li>
								<li><router-link :to="{name: 'KorManagement' }">경영철학</router-link></li>
								<li><router-link :to="{name: 'KorHistory' }" class="on">연혁</router-link></li>
								<!--<li><router-link :to="{name: '' }">그룹안내</router-link></li>-->
								<li><router-link :to="{name: 'KorLocation' ,query:{location:'B'} }">위치안내</router-link></li>
								<li><router-link :to="{name: 'KorCurtainWallProcess', query:{location:'PD'} }">공장소개</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'KorCurtainWall' }">CURTAIN WALL</router-link>
							<ul>
								<li><router-link :to="{name: 'KorCurtainWall' }">커튼월소개</router-link></li>
								<li><router-link :to="{name: 'KorCurtainWallProcess', query:{location:'PR'} }">PROCESS</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'KorDomestic' }">PROJECTS</router-link>
							<ul>
								<li><router-link :to="{name: 'KorDomestic' ,query :{ lang:'kor', type :'D'}}">국내프로젝트</router-link></li>
								<li><router-link :to="{name: 'KorDomestic' ,query :{ lang:'kor', type :'O'}}">해외프로젝트</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'KorPrCenterCi' }">PR CENTER</router-link>
							<ul>
								<li><router-link :to="{name: 'KorPrCenterCi' }">CI소개</router-link></li>
								<li><router-link :to="{name: 'KorPrCenterNews' }">에코월뉴스</router-link></li>
								<li><router-link :to="{name: 'KorPrCenterVideo' }">홍보영상</router-link></li>
							</ul>
						</li>
						<li class="dp1">
							<router-link :to="{name: 'KorInquire' }">SUPPORT</router-link>
							<ul>
								<li><router-link :to="{name: 'KorInquire' }">문의하기</router-link></li>
								<!-- <li><router-link :to="{name: 'KorAudit' }">사이버감사실</router-link></li> -->
							</ul>
						</li>
						<!-- <li class="dp1">
							<router-link :to="{name: 'KorTalent' }">RECRUIT</router-link>
							<ul>
								<li><router-link :to="{name: 'KorTalent' }">인재상</router-link></li>
								<li><router-link :to="{name: 'KorEmployment' }">인사제도</router-link></li>
								<li><router-link :to="{name: 'KorWelfare' }">임금복리후생</router-link></li>
							</ul>
						</li> -->
					</ul>
				</nav>
				<div class="gnb_side">
					<div class="lan">
						<a style="cursor:pointer" class="on">KR</a>
						<a style="cursor:pointer" @click="goEng()" >EN</a>
					</div>
					<div class="burger">
						<div class="x"></div>
						<div class="y"></div>
						<div class="z"></div>
					</div>
				</div>
			</div>
			<div class="circle"></div>
		</header>
		<!-- //header -->
</template>

<script>

export default {
  name: 'WebHeader',
  data() {
    return {
      userName: '',
    }
  },

  computed: {
    title() {
      return this.$route.meta.title || ''
    }
  },
  mounted() {
    console.dir(this.$store)
  },
  methods: {
    leftHidden() {
      this.$emit('leftHidden')
    },
    logout() {
      sessionStorage.removeItem('eotoken')
      localStorage.removeItem('eotoken')
      this.$router.push({
        name: 'Login'
      })
    },
    goEng(){
      console.dir(this.$route)
      if (this.$route.name == 'Main'){
        this.$router.push({
          name: 'EngMain'
        })
        return;
      }
      if (this.$route.name =='KorPrCenterNewsView'){
        this.$router.push({
          name: 'EngPrCenterNews'
        })
        return;
      }

      if (this.$route.name =='KorProjectView'){
        this.$router.push({
          name: 'EngDomestic',
          query : {lang:'eng', type:this.$route.query.type}
        })
        return;
      }
      if (this.$route.name =='KorCurtainWallProcess'){
        this.$router.push({
          name: 'EngCurtainWallProcess',
          query : { location:this.$route.query.location}
        })
        return;
      }

      if (this.$route.name =='KorLocation'){
        this.$router.push({
          name: 'EngLocation',
          query : {location:this.$route.query.location}
        })
        return;
      }

      this.$router.push({
        name: this.$route.name.replace('Kor', 'Eng')
      })

    }

  }
}
</script>
