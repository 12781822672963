
<template>


    <!-- Wrap -->
    <div id="Wrap">
      <Header />
      <slot />
      <Footer />
    </div>
</template>

<script>


import Footer from '@/components/KorFooter.vue'
import Header from '@/components/KorHeader.vue'

export default {
  name: 'WebLayout',
  components: {
    Footer,
    Header
  },
  mounted() {
    this.$nextTick(function () {
      var $header = $("#header");
      var $gnb = $(".gnb");
      header();


      //header
      function header(){
        let count = 0 ;
        function wSize() {
          if (window.innerWidth > 1200) {
            $gnb.removeClass("gnb_mo");
            closeMenu();
            $(".gnb > ul > li > a").each(function(index){
              if($(this).next("ul").length >= 1){
                $(this).attr("href", $(this).next("ul").find("a:eq(0)").attr("href"));
              }
            });
          } else {
            count ++;
            $gnb.addClass("gnb_mo");
            $(".gnb.gnb_mo > ul > li > a").each(function(index){
              if($(this).next("ul").length > 0){
                $(this).attr("href", "#.");
                $(this).on("click",function(){
                  if($(this).next("ul").is(":visible")){
                    $(".gnb.gnb_mo > ul > li > ul").slideUp();
                  } else{
                    $(".gnb.gnb_mo > ul > li > ul").slideUp();
                    $(this).next("ul").slideDown();
                  }
                });
              }
            });
          }
        }
        $(window).resize(function () {
          if(count <= 0){
            wSize();
          }
        });
        wSize();



        $(window).scroll(function(){
          var win_scroll = $(window).scrollTop();
          if(win_scroll > 100){
            $("#header").addClass('header_scroll');
          } else{
            $("#header").removeClass('header_scroll');
          }
        });


        //버거

        $('div.burger').on('click', function(){
         
          if( !$(this).hasClass('open') ){ openMenu(); }
          else { closeMenu(); }
        });

        $('div.menu ul li a').on('click', function(e){
          e.preventDefault();
          closeMenu();
        });

        $('#header .gnb .dp1 ul  a').on('click',function(){
          closeMenu();
        });

        function openMenu(){
          $header.addClass('open');
          $('div.circle').addClass('expand');

          $('div.burger').addClass('open');
          $('div.x, div.y, div.z').addClass('coll');
          $gnb.find("li.dp1").addClass('animate');

          setTimeout(function(){
            $('div.y').hide();
            $('div.x').addClass('rotate30');
            $('div.z').addClass('rotate150');
          }, 70);
          setTimeout(function(){
            $('div.x').addClass('rotate45');
            $('div.z').addClass('rotate135');
          }, 120);
        }

        function closeMenu(){
          $header.removeClass('open');
          $('div.burger').removeClass('open');
          $('div.x').removeClass('rotate45').addClass('rotate30');
          $('div.z').removeClass('rotate135').addClass('rotate150');
          $gnb.find("li").removeClass('animate');

          setTimeout(function(){
            $('div.x').removeClass('rotate30');
            $('div.z').removeClass('rotate150');
          }, 50);
          setTimeout(function(){
            $('div.y').show();
            $('div.x, div.y, div.z').removeClass('coll');
          }, 70);
          setTimeout(function(){
            $('div.circle').removeClass('expand');
          }, 150);
        }

      }

    })
  },



}
</script>
