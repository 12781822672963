import router from '..'

const routes = {
  path: '/about',
  name: 'KorAboutUsMain',
  meta: {
    layout: 'WebLayout',
    title: '센터관리',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/about/Main.vue'),
  children: [
    {
      path: 'greeting',
      alias: '',
      name: 'KorGreeting',
      title : '회원',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/about/Greeting.vue'),
      meta: {
        lang : 'kor'
      }
    },
    {
      path: 'management',
      alias: '',
      name: 'KorManagement',
      title : '회원',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/about/Management.vue'),
      meta: {
        lang : 'kor'

      }
    },
    {
      path: 'location',
      alias: '',
      name: 'KorLocation',
      title : '회원',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/about/Location.vue'),
      meta: {
        lang : 'kor'
      }
    }
    ,
    {
      path: 'History',
      alias: '',
      name: 'KorHistory',
      title : '연혁',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/about/History.vue'),
      meta: {
        lang : 'kor'
      }
    }
  ]
}

export default routes
