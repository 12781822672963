import router from '..'

const routes = {
  path: '/curtainwall',
  name: 'KorCurtainMain',
  meta: {
    layout: 'WebLayout',
    title: 'About Us',
    dep: 1
  },
  component: () => import('@/views/kor/curtain/Main.vue'),
  children: [
    {
      path: '',
      alias: '',
      name: 'KorCurtainWall',
      title : '커튼월 소개',
      component: () => import('@/views/kor/curtain/CurtainWall.vue'),
      meta: {
        lang : 'kor'

      }
    },
    {
      path: 'process',
      alias: '',
      name: 'KorCurtainWallProcess',
      title : '커튼월 Process',
      component: () => import('@/views/kor/curtain/Process.vue'),
      meta: {
        lang : 'kor'

      }
    },

  ]
}

export default routes
