import axios from '@/api/axios/index'
import qs from 'qs'
import _ from 'lodash'

const ax = (url, method, data, result) => {
  let finalData = {
    result_code: '200',
    message: ''
  }

  let options = {}
  if (method == ('GET') || method == ('DELETE')){
    options = {
      method: method,
      url: url,
      params : data
    }
  } else {
    if (data.toString()=='[object FormData]'){
      options = {
        method: method,
        url: url,
        data : data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    } else {
      options = {
        method: method,
        url: url,
        data : qs.stringify(data)
      }
    }
  }
  try {
    axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error(url, err)
    result(false, err)
  }
}

export default {
  ax
}
