import router from '..'

const routes = {
  path: '/en/support',
  name: 'EngSupportMain',
  meta: {
    layout: 'EngLayout',
    title: '센터관리',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/support/Main.vue'),
  children: [
    {
      path: 'inquire',
      alias: '',
      name: 'EngInquire',
      title : '문의하기',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/support/Inquire.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'
      }
    },
    {
      path: 'audit',
      alias: '',
      name: 'EngAudit',
      title : '사이버감사실',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/support/Audit.vue'),
      meta: {
        btnOn: 1,
        lang : 'eng'

      }
    }
  ]
}

export default routes
