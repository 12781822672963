
<template>
  <!-- footer -->
  <footer id="footer" class="container">
    <div class="f_link">
      <ul>
        <li><router-link :to="{name: 'KorPrivacy' }">개인정보 취급방침</router-link></li>
        <li><router-link :to="{name: 'KorEmail' }">이메일무단수집거부</router-link></li>
      </ul>
    </div>
    <div class="f_info">
      <div class="info">
        <address>
          <p>본사 및 영업부. 서울특별시 강남구 영동대로 333 (대치동)  </p>
          <p>대표번호. 02)6353-0114</p>
          <p>팩스번호. 02)6353-0100</p>
        </address>
        <p class="copy">COPYRIGHT ⓒ LOTTE EcoWall Co. ALL RIGHTS RESERVED.</p>
      </div>
      <!-- <div class="family">
        <select  onchange="window.open(value,'_blank');">
          <option>Family Site</option>
          <option value="https://www.iljin.co.kr/kor/main.asp">일진그룹</option>
          <option value="http://www.iljinelectric.co.kr/main.jsp">일진전기(주)</option>
          <option value="http://www.iljinm.co.kr/main.jsp">일진 Materials(주)</option>
          <option value="http://www.iljindiamond.co.kr/main.jsp">일진다이아몬드(주)</option>
          <option value="http://www.iljindisplay.co.kr/main.jsp">일진디스플레이(주)</option>
          <option value="http://www.iljinsteel.com/main.jsp">일진제강(주)</option>
          <option value="https://www.iljincns.co.kr/">일진씨앤에스</option>
          <option value="http://www.composite.co.kr/main.jsp">일진하이솔루스</option>
          <option value="http://www.iljinec.co.kr/">일진건설</option>
        </select>
      </div> -->
    </div>
  </footer>
  <!-- //footer -->
</template>

<script>

export default {
  name: 'Subheader',
  data() {
    return {
      userName: '',
    }
  },

  computed: {
    title() {
      return this.$route.meta.title || ''
    }

  },
  mounted() {
    console.dir(this.$store)
  },
  methods: {
    leftHidden() {
      this.$emit('leftHidden')
    },
    logout() {
      sessionStorage.removeItem('eotoken')
      localStorage.removeItem('eotoken')
      this.$router.push({
        name: 'Login'
      })
    },

  }
}
</script>
