import router from '@/router'
import { inject } from 'vue'

export const Lang = {
  state: () => ({
    lang : '',
  }),
  mutations: {
    // this.$store.commit("setProvision", provision)
    setLang(state, lang) {
      state.lang = lang
    },
  },
  actions: {

  },
  getters: {
    lang(state){
      return state.lang
    }
  }
}
