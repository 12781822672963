import router from '..'

const routes = {
  path: '/admin/stats',
  name: 'AdminStatsMaster',
  meta: {
    layout: 'AdminLayout',
    title: '통계',
    dep: 4
  },
  component: () => import('@/views/admin/Main.vue'),
  children: [
    {
      path: 'visit',
      alias: '',
      name: 'AdminStatsVisit',
      title : '방문통계',
      component: () => import('@/views/admin/statistic/Visit.vue'),
      meta: {
        lang : 'admin'
      }
    },
    {
      path: 'inflow',
      alias: '',
      name: 'AdminStatsInflow',
      title : '유입경로',
      component: () => import('@/views/admin/statistic/Inflow.vue'),
      meta: {
        lang : 'admin'
      }
    }
  ]
}

export default routes
