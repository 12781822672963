import router from '..'

const routes = {
  path: '/admin/board',
  name: 'AdminBoardMaster',
  meta: {
    layout: 'AdminLayout',
    title: '게시판',
    dep: 3
  },
  component: () => import('@/views/admin/board/Main.vue'),
  children: [
    {
      path: 'news/list',
      alias: '',
      name: 'AdminNewsList',
      title : '뉴스/매거진',
      component: () => import('@/views/admin/board/NewsList.vue'),
      meta: {
        lang : 'admin'
      }
    },
    {
      path: 'news/write',
      alias: '',
      name: 'AdminNewsWrite',
      title : '뉴스/매거진',
      component: () => import('@/views/admin/board/NewsWrite.vue'),
      meta: {
        lang : 'admin'
      }
    },
    {
      path: 'inquire/list',
      alias: '',
      name: 'AdminInquireList',
      title : '문의하기',
      component: () => import('@/views/admin/board/InquireList.vue'),
      meta: {
        lang : 'admin'
      }
    },
    {
      path: 'inquire/write',
      alias: '',
      name: 'AdminInquireWrite',
      title : '문의하기',
      component: () => import('@/views/admin/board/InquireWrite.vue'),
      meta: {
        lang : 'admin'
      }
    }
  ]
}

export default routes
