import router from '..'

const routes = {
  path: '/en/project',
  name: 'EngProjectMain',
  meta: {
    layout: 'EngLayout',
    title: 'Project',
    dep: 1
  },
  component: () => import('@/views/eng/curtain/Main.vue'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'EngDomestic',
      title : '커튼월 소개',
      component: () => import('@/views/eng/project/Domestic.vue'),
      meta: {
        lang : 'eng'
      }
    },
    {
      path: 'global',
      alias: '',
      name: 'EngGlobal',
      title : '커튼월 소개',
      component: () => import('@/views/eng/project/Global.vue'),
      meta: {
        lang : 'eng'
      }
    },
    {
      path: 'view',
      alias: '',
      name: 'EngProjectView',
      title : '커튼월 소개',
      component: () => import('@/views/eng/project/View.vue'),
      meta: {
        lang : 'eng'
      }
    }
  ]
}

export default routes
