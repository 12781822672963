import router from '..'

const routes = {
  path: '/guide',
  name: 'KorGuideMain',
  meta: {
    layout: 'WebLayout',
    title: '이용가이드',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/guide/Main.vue'),
  children: [
    {
      path: 'privacy',
      alias: '',
      name: 'KorPrivacy',
      title : '개인정보처리방침',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/guide/Privacy.vue'),
      meta: {
        lang : 'kor'
      }
    },
    {
      path: 'email',
      alias: '',
      name: 'KorEmail',
      title : '이메일무단수집거부',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/kor/guide/Email.vue'),
      meta: {
        lang : 'kor'

      }
    }
  ]
}

export default routes
