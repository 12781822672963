<template>
  <div id="app" class="app">
    <base-layout />
  </div>
</template>

<script>

import BaseLayout from '@/layouts/BaseLayout'

export default {
  name: 'App',
  components: {BaseLayout},
  mounted (){
    this.loadCss();
  },
  watch: {
    '$route.name': function() {
      this.loadCss();
    }
  },
  methods :{
    loadCss(){

    }
  }
}
</script>
<style lang="scss"></style>
