import router from '..'

const routes = {
  path: '/en/about',
  name: 'EngAboutUsMain',
  meta: {
    layout: 'EngLayout',
    title: '센터관리',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/about/Main.vue'),
  children: [
    {
      path: 'greeting',
      alias: '',
      name: 'EngGreeting',
      title : '회원',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/about/Greeting.vue'),
      meta: {
        lang : 'eng'
      }
    },
    {
      path: 'management',
      alias: '',
      name: 'EngManagement',
      title : '회원',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/about/Management.vue'),
      meta: {
        lang : 'eng'

      }
    },
    {
      path: 'location',
      alias: '',
      name: 'EngLocation',
      title : '회원',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/about/Location.vue'),
      meta: {
        lang : 'eng'
      }
    }
    ,
    {
      path: 'History',
      alias: '',
      name: 'EngHistory',
      title : '연혁',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/eng/about/History.vue'),
      meta: {
        lang : 'eng'
      }
    }
  ]
}

export default routes
