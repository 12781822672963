<template>

  <div id="collapseExample" class="header1">
    <div class="sidebar-heading">
      <router-link to="/">
        <img src="/img/logo.png" />
      </router-link>
    </div>
    <div class="sidebar-body">
      <ul>
        <li>
          <router-link :to="{name: 'Main'}" :class="{on: page == 8}">
            <span>홈</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: '', query: {businessType: 'B2C'}}" :class="{on: page == 1}">
            <span>신규고객 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: '', query: {businessType: 'B2C'}}" :class="{on: page == 2}">
            <span>계약건 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: '', query: {businessType: 'B2C'}}" :class="{on: page == 3}">
            <span>완료공사 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: '', query: {businessType: 'B2C', host: 'U'}}" :class="{on: page == 4}">
            <span>A/S 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: '', query: {businessType: 'B2C'}}" :class="{on: page == 5}">
            <span>경영관리</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{name: '', query: {businessType: 'B2C'}}" :class="{on: page == 6}">
            <span>휴지통</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: ''}" :class="{on: page == 7}">
            <span>설정</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: ''}" :class="{on: page == 9}">
            <span>공지사항</span>
          </router-link>
        </li>
      </ul>
      <div class="company-info">
        <p
          class="text14 dropdown-toggle collapsed"
          data-bs-toggle="collapse"
          href="#company"
          role="button"
          aria-expanded="false"
          aria-controls="company"
        >
          주식회사 제니엘
          <span></span>
        </p>
        <div class="collapse multi-collapse" id="company">
          <p class="text14">대표자 : OOO</p>
          <p class="text14">서울특별시 강남구 테헤란로2길 OO, OOOO호 (역삼동, 비젼타워)</p>
          <p class="text14">전화: 0000.0000</p>
          <p class="text14">이메일: admin@eoapps.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {
      page: this.$route.meta.dep
    }
  },
  created() {
    let accessToken = sessionStorage.getItem('eotoken') || localStorage.getItem('eotoken')

    if (accessToken == null) {
      this.$router.replace('/')
      return
    }
  },
  methods: {

  },
  watch: {
    '$route.name': function () {
      this.page = this.$route.meta.dep

    }
  }
}
</script>
